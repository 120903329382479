import { PropostalInfo } from "./types";

export function calculatePropostalsSumByParam(propostals:Array<PropostalInfo>,param:string,showParamName?:(p:string)=>string){
    let data:{[key:string]:{total:number,numberOfPropostals:number,param:string}}={};
     let sumTotal:{numberOfPropostals:number,total:number}={numberOfPropostals:0,total:0};
      for(let p of propostals){
          //@ts-ignore
          let uparam=showParamName?showParamName(p[param]):p[param];
          if(!data[uparam]){
            data[uparam]={
                total:0,
                numberOfPropostals:0,
                param:uparam,
            }
          }
          data[uparam].total+=p.client_withdrawn_value;
          data[uparam].numberOfPropostals++;
      }
      for(let d of Object.values(data)){
          sumTotal.total+=d.total;
          sumTotal.numberOfPropostals+=d.numberOfPropostals;
      }
      return {data,sumTotal};
}

export function getComissionByTableName(name:string){
    let uname=name.toLowerCase();
    if(uname=='turbo')
        return 0.28998;
    if(uname=='acelera')
        return 0.18;
    if(uname=='normal')
        return 0.155;
    return 0;
}